
body {
  font-family: sans-serif;
  background: #262626;
  color: #ddd;
  font-size: 90%;
}

.app {
  display: flex;
  gap: 2%;
  justify-content: space-evenly;
}

.tech-list {
  max-width: 960px;
  font-size: 0.8em;
  display: grid;
  gap: 0.5em;
  grid-auto-flow: row;
  justify-content: left;

  .melee {
    grid-row: 1;
  }

  .ranged {
    grid-row: 2;
  }

  .cavalry {
    grid-row: 3;
  }

  .siege {
    grid-row: 4;
  }

  .water {
    grid-row: 5;
  }

  .eco {
    grid-row: 6;
  }

  .info-panel {
    margin-top: 1em;
    grid-row: 7;
    grid-column: auto / span 2;

    .civ-logo {
      height: 2em;
      vertical-align: middle;
      margin-right: 0.5em;
    }
  }
}

.player-table {
  width: min-content;
}

.tiered-tech {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  grid-template-rows: auto 1.5em 1em;
  width: 10em;
  height: 10em;
  font-size: 0.7em;
  overflow: hidden;

  .name {
    color: white;
    padding: 0.5em;
    text-align: center;
  }

  &.disabled .name {
    color: black;
  }

  .tech-image-container {
    position: relative;
    height: 100%;

    .tech-image, .tech-cross {
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .tech-cross {
      position: absolute;
      background-image: url("../img/cross.svg");
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  &.disabled .tech-image {
    filter: #{"grayscale(100%)"};
  }

  .tier-bar {
    align-self: stretch;
    justify-self: stretch;
    display: flex;
    min-height: 1em;
    gap: 3%;

    .tier-bar-part {
      flex-grow: 1;

      &.green {
        background: greenyellow;
      }

      &.red {
        background: darkred;
      }

      &.gold {
        background: gold;
      }

      &:last-child {
        border-right: 0px;
      }
    }
  }
}

.block-red {
  background: indianred;
}

.block-green {
  background: darkolivegreen;
}

.block-purple {
  background: mediumpurple;
}

.block-blue {
  background: cornflowerblue;
}

.block-grey {
  background: darkgrey;
}

.team-player {
  .player-color1 {
    background-color: #405BFE;
  }

  .player-color2 {
    background-color: #FF0000;
  }

  .player-color3 {
    background-color: #00FF00;
  }

  .player-color4 {
    background-color: #FFFF00;
  }

  .player-color5 {
    background-color: #00FFFF;
  }

  .player-color6 {
    background-color: #FF57B3;
  }

  .player-color7 {
    background-color: #797979;
  }

  .player-color8 {
    background-color: #FF9600;
  }

  .player-color {
    color: #262626;
    width: 1.5em;
    text-align: center;
  }

  .team-civ-icon {
    height: 1.5em;
    vertical-align: middle;
    margin-right: 0.5em;
  }

  .team-civ {
    white-space: nowrap;
  }

  .team-civ-type {
    font-size: 0.7em;
  }

  .team-bonus {
    font-size: 0.7em;
  }

  .special-unit {
    text-align: right;

    .tiered-tech {
      display: inline-flex;
      font-size: 0.3em;
    }
  }

  .mini-tech-list {
    font-size: 0.3em;

    & > * {
      display: inline-flex;
    }
  }
}

.player-name {
  .name-civ {
    .civ-logo {
      height: 1.5em;
      vertical-align: text-bottom;
      margin-right: 0.2em;
    }

    font-size: 0.6em;
  }
}